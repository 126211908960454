<template>
  <v-content class="app-content" :class="contentClass">
    <v-container class="app-content__container">
      <v-slide-x-transition appear mode="out-in">
        <router-view></router-view>
      </v-slide-x-transition>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: 'app-content',
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    contentClass() {
      let result = '';
      if (this.center) {
        result += ' app-content--center ';
      }
      if (this.$context.fullWidth) {
        result += ' app-content--full_width ';
      } else {
        result += 'app-content--cut_width';
      }
      return result;
    }
  },
}
</script>

<style lang="less">
  .app-content {
    &&.app-content--center {
      .app-content__container {
        display: flex;
        margin: auto;
        height: 100%;
      }
    }
    &&.app-content--full_width {
      .app-content__container {
        max-width: 100%!important;
        height: 100%;
      }
    }
    &&.app-content--cut_width {
      .app-content__container {
        max-width: 1280px!important;
        height: 100%;
      }
    }
  }
</style>