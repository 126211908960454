import './styles/index.less';
import App from 'fude/fude-web-vue';
import context from './context';
import components from './components';
import consts from './consts';

export default App
    .settings({
        localization : {
            current    : 'ru',
            target     : 'research-web',
            dateFormat : 'DD.MM.YYYY HH:mm'
        },
        sockets : {

        }
    })
    .consts(consts)
    .context(context)
    .components(
        components.MainMenu,
        components.Drawer,
        components.Content,
        components.Footer,
        components.ToolbarActionList,
        components.AxDateField
    );