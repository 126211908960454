import User from './user';

export default {
    data() {
        return {
            title       : '<a href="/#/" style="color: rgba(0,0,0,0.87)">JOB24</a>',
            subTitle    : '',
            topActions  : null,
            fullWidth   : false,
            drawer      : {
                items : null,
                hover : false,
                show  : false,
                dense : false
            }
        }
    },

    remoteData : {
        async user() {
            let res = await this.$http.get('/api/v1/users/current?' + new Date().getTime());
            return new User(res.data);
        }
    }
}