<template>
  <div>
    <div v-if="$context.user && $context.user.id">
      <span v-if="hasOtherOrganization">
        <a @click="$navigate('/#/organizations')" style="cursor: pointer">
          {{ organizationName }}
        </a>
      </span>
      <span v-if="!hasOtherOrganization">
        <b>
          {{ organizationName }}
        </b>
      </span>
      <v-menu content-class="app-main-menu" offset-y left>
        <fude-btn-resizable
          slot="activator"
          :title="userName"
          color="primary"
          icon="mdi-account"
        />
        <v-list>
          <template v-for="item in items">
            <v-divider v-if="item.divider" :key="item.name" />
            <v-list-tile v-else-if="item.click" @click="item.click" :key="item.name">
              <v-list-tile-title class="text-truncate">{{ item.name }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-else v-bind:href="item.href" :key="item.name">
              <v-list-tile-title class="text-truncate">{{ item.name }}</v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list>
      </v-menu>
    </div>

    <!-- <div v-if="!$context.user.id && loginPage">
      <v-menu content-class="app-main-menu" offset-y left>
        <fude-btn-resizable
          slot="activator"
          title="Регистрация"
          style="background-color: #FF6600; color: white;"
          icon="mdi-account"
          @click="registration"
        />
      </v-menu>
    </div> -->

    <div v-if="!$context.user.id && !loginPage">
      <v-menu content-class="app-main-menu" offset-y left>
        <fude-btn-resizable
          slot="activator"
          title="Войти"
          color="primary"
          icon="mdi-account"
          @click="login"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-main-menu',

  props: {
    loginPage: {
      type: Boolean,
    }
  },

  computed: {
    userName() {
      let source = this.$context.user.full_name;
      if (source != null) {
        let array = source.split(' ');
        let result = '';
        if (array.length === 1) result = array[0];
        if (array.length === 2) result = array[0] + ' ' + array[1][0] + '.';
        if (array.length === 3) result = array[0] + ' ' + array[1][0] + '. ' + array[2][0] + '.';

        return result;
      } else {
        return null;
      }
    },

    organizationName() {
      let organization = this.$context.user.roles.filter(r => r.organization_id === window.localStorage['organization']);
      if (!organization || !organization.length) return null;
      return organization[0].organization_name;
    },

    items() {
      let result = [];

      if (this.$context.user.isResumeSearch || this.$context.user.isResearcher || this.$context.user.isAdmin) {
        result.push({name: 'Поиск резюме', click: () => this.$navigate('/#/search')});
        result.push({name: 'История поисков', click: () => this.$navigate('/#/my_search')});
      }

      if (this.$context.user.isAddingResume || this.$context.user.isAdmin) {
        result.push({name: 'Добавление резюме', click: () => this.$navigate('/#/search')});
      }

      result.push({name: 'Купленные резюме', click: () => this.$navigate('/#/buyed')});

      // if (this.$context.user.isApplicant || this.$context.user.isResearcher || this.$context.user.isPublishVacancies || this.$context.user.isOrganizationAdmin || this.$context.user.isAdmin) {
      //   result.push({name: 'Поиск вакансий', click: () => this.$navigate('/#/vacancies/list')});
      // }

      if (this.$context.user.isApplicant || this.$context.user.isResearcher || this.$context.user.isAdmin) {
        result.push({name: 'Поиск вакансий', click: () => this.$navigate('/#/vacancies/list')});
      }

      // if (this.$context.user.isPublishVacancies || this.$context.user.isOrganizationAdmin || this.$context.user.isAdmin) {
      //   result.push({name: 'Мои вакансии', click: () => this.$navigate('/#/vacancies/my-vacancies')});
      //   result.push({name: 'Доска вакансий', click: () => this.$navigate('/#/recruiting')});
      // }

      if (this.$context.user.isPublishVacancies || this.$context.user.isAdmin) {
        result.push({name: 'Мои вакансии', click: () => this.$navigate('/#/vacancies/my-vacancies')});
      }
      result.push({name: 'Канбан', click: () => this.$navigate('/#/recruiting')});

      if (this.$context.user.isOrganizationAdmin || this.$context.user.isAdmin) {
        result.push({name: 'Управление', click: () => this.$navigate('/#/organization-admin')});
      }

      if (this.$context.user.isAdmin) {              
        result.push({name: 'Администрирование', click: () => this.$navigate('/admin/')});
      }
             
      result.push({name: 'Личный кабинет', click: () => this.$navigate('/#/profile')});
      result.push({name: 'Баланс', click: () => this.$navigate('/#/balance')});
      result.push(
        { divider: true },
        { name: 'Выход', click: () => this.logout() }
      );

      return result;
    },

    hasOtherOrganization() {
      let organizations = [... new Set(this.$context.user.roles.map(r => r.organization_id))];
      if (organizations.length > 1) return true;
      return false;
    }
  },

  methods: {
    registration() {
      window.location.href = `/#/registration/`;
    },
    login() {
      this.$navigate('/login/');
    },
    logout() {
      this.$http.post('/api/v1/users/logout').then(() => {
      window.localStorage.removeItem('organization');
      window.localStorage.removeItem('vacancy_id');
      window.location.href = `/login/#`;
      }).catch((error) => {
        console.log('error', error);
      });
    }
  }
}
</script>

<style lang="less">
.app-main-menu {
 
  .v-list__tile {
    height: 36px;
  }
  
  .v-list__tile__title {
    max-width: 200px;
  }
}
</style>