class User {
    constructor(data) {
        this.id = data.id;
        this.name = data.name || data.email;
        this.full_name = data.full_name;
        this.email = data.email;
        this.phone = data.phone;
        this.region_id = data.region_id;
        this.notification_settings = data.notification_settings;
        this.roles = data.roles || [];
        
        this._isAdmin = !!this.roles.find((r) => r.role_code === 'admin');
        
        this._isFreelancer = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.organization_type === 'freelance'
        :
            r.organization_type === 'freelance');

        this._isOrganizationAdmin = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.role_code === 'organization_admin'
        :
            r.role_code === 'organization_admin');

        this._isResumeSearch = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.role_code === 'resume_search'
        :
            r.role_code === 'resume_search');

        this._isResumeBuy = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.role_code === 'resume_buy'
        :
            r.role_code === 'resume_buy');

        this._isUser = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.role_code === 'user'
        :
            r.role_code === 'user');

        this._isResearcher = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.role_code === 'researcher'
        :
            r.role_code === 'researcher');

        this._isAddingResume = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.role_code === 'adding_resume'
        :
            r.role_code === 'adding_resume');

        this._isPublishVacancies = !!this.roles.find((r) => window.localStorage['organization'] && window.localStorage['organization'].indexOf('null') < 0 ?
            r.organization_id === window.localStorage['organization'] && r.role_code === 'publish_vacancy'
        :
            r.role_code === 'publish_vacancy');

        this._isApplicant = !!this.roles.find((r) => r.role_code === 'applicant');

    }

    isInRole(role) {
        return !!this.roles.find((r) => r.role_code === role);
    }
    get isTestUser() {
        return this.email === 'test';
    }
    get isAdmin() {
        return this._isAdmin;
    }
    get isOrganizationAdmin() {
        return this._isOrganizationAdmin;
    }
    get isFreeLancer() {
        return this._isFreelancer;
    }
    get isResumeSearch() {
        return this._isResumeSearch;
    }
    get isResumeBuy() {
        return this._isResumeBuy;
    }
    get isUserRole() {
        return this._isUser;
    }
    get isApplicant() {
        return this._isApplicant;
    }
    get isResearcher() {
        return this._isResearcher;
    }
    get isAddingResume() {
        return this._isAddingResume;
    }
    get isPublishVacancies() {
        return this._isPublishVacancies;
    }

    get isOnlySearch() {
        return !(this._isResumeBuy || this._isAdmin || this._isUser);
    }
}

export default User;