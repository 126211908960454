import MainMenu from './main-menu';
import Drawer from './drawer';
import Content from './content';
import Footer from './footer';
import ToolbarActionList from './toolbar-action-list';
import AxDateField from './ax-date-field';

export default {
  MainMenu,
  Drawer,
  Content,
  Footer,
  ToolbarActionList,
  AxDateField,
}