export default {
    search_types: [
        {id: 'all_words', name: 'все слова'},
        {id: 'any_word', name: 'любое слово'},
        {id: 'exclude_word', name: 'не содержит'}
    ],
    search_areas: [
        { id:'resume', name:'в названии резюме' },
        { id:'education', name:'в образовании' },
        { id:'tag', name:'в ключевых навыках' },
        { id:'experience', name:'в опыте работы', childs: [
            { id:'company', name:'в компаниях и отраслях' },
            { id:'position', name:'в должностях' },
            { id:'duty', name:'в обязанностях' }
        ]}
    ],
    experience_periods:[
        { id:'all', name:'за все время опыта' },
        { id:'last_year', name:'за последний год' },
        { id:'three_years', name:'за последние 3 года' },
        { id:'six_years', name:'за последние 6 лет' }
    ],
    search_periods: [
        { id:'all_time', name:'за весь период' },
        { id:'day', name:'за сутки' },
        { id:'three_days', name:'за трое суток' },
        { id:'week', name:'за неделю' },
        { id:'month', name:'за месяц' },
        { id:'year', name:'за год' },
        { id:'interval', name:'за интервал' },
    ],
    sexes: [
        { id:'any', name:'не важно' },
        { id:'male', name:'мужской' },
        { id:'female', name:'женский' },
    ],
    move_types: [
        { id:'live_or_move_here', name:'Живут в указанном регионе или готовы переехать в него' },
        { id:'live_here', name:'Живут в указанном регионе' },
        { id:'live_here_or_move_everywhere', name:'Живут в указанном регионе и готовы к переезду куда-либо' },
        { id:'move_here', name:'Не живут в указанном регионе, но готовы переехать в него' },
    ],
    experience_types: [
        { id:'no', name:'Нет опыта' },
        { id:'from1to3', name:'От 1 года до 3 лет' },
        { id:'from3to6', name:'От 3 до 6 лет' },
        { id:'over6', name:'Более 6 лет' },
    ],
    required : [
        {id: 'surname', name: 'Фамилия'},
        {id: 'name', name: 'Имя'},
        {id: 'phone', name: 'Номер телефона'},
        {id: 'email', name: 'e-mail'},
        {id: 'last_employer', name: 'Последнее место работы'},
        {id: 'skype', name: 'Skype'},
        {id: 'social_network', name: 'Соцсети'}
    ],
    sort_orders: [
        { id:'change_date', name:'По дате изменения' },
        { id:'salary_asc', name:'По возрастанию зарплаты' },
        { id:'salary_desc', name:'По убыванию зарплаты' }
    ],
    items_per_page: [
        { id:20, name:'20 резюме' },
        { id:50, name:'50 резюме' },
        { id:100, name:'100 резюме' }
    ],
    months: [
        { id:1,  name:'Январь'   },
        { id:2,  name:'Февраль'  },
        { id:3,  name:'Март'     },
        { id:4,  name:'Апрель'   },
        { id:5,  name:'Май'      },
        { id:6,  name:'Июнь'     },
        { id:7,  name:'Июль'     },
        { id:8,  name:'Август'   },
        { id:9,  name:'Сентябрь' },
        { id:10, name:'Октябрь'  },
        { id:11, name:'Ноябрь'   },
        { id:12, name:'Декабрь'  },
    ],
    months_name: [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
    ],
    ages: new Map([
        [11, 'лет'],
        [12, 'лет'],
        [13, 'лет'],
        [14, 'лет'],
        [15, 'лет'],
        [16, 'лет'],
        [17, 'лет'],
        [18, 'лет'],
        [19, 'лет'],
        [20, 'лет'],
        [21, 'год'],
        [22, 'года'],
        [23, 'года'],
        [24, 'года'],
        [25, 'лет'],
        [26, 'лет'],
        [27, 'лет'],
        [28, 'лет'],
        [29, 'лет'],
        [30, 'лет'],
        [31, 'год'],
        [32, 'года'],
        [33, 'года'],
        [34, 'года'],
        [35, 'лет'],
        [36, 'лет'],
        [37, 'лет'],
        [38, 'лет'],
        [39, 'лет'],
        [40, 'лет'],
        [41, 'год'],
        [42, 'года'],
        [43, 'года'],
        [44, 'года'],
        [45, 'лет'],
        [46, 'лет'],
        [47, 'лет'],
        [48, 'лет'],
        [49, 'лет'],
        [50, 'лет'],
        [51, 'год'],
        [52, 'года'],
        [53, 'года'],
        [54, 'года'],
        [55, 'лет'],
        [56, 'лет'],
        [57, 'лет'],
        [58, 'лет'],
        [59, 'лет'],
        [60, 'лет'],
        [61, 'год'],
        [62, 'года'],
        [63, 'года'],
        [64, 'года'],
        [65, 'лет'],
        [66, 'лет'],
        [67, 'лет'],
        [68, 'лет'],
        [69, 'лет'],
        [70, 'лет'],
        [71, 'год'],
        [72, 'года'],
        [73, 'года'],
        [74, 'года'],
        [75, 'года'],
        [76, 'лет'],
        [77, 'лет'],
        [78, 'лет'],
        [79, 'лет'],
        [80, 'лет'],
        [81, 'год'],
        [82, 'года'],
        [83, 'года'],
        [84, 'года'],
        [85, 'лет'],
        [86, 'лет'],
        [87, 'лет'],
        [88, 'лет'],
        [89, 'лет'],
        [90, 'лет'],
        [91, 'год'],
        [92, 'года'],
        [93, 'года'],
        [94, 'года'],
        [95, 'лет'],
        [96, 'лет'],
        [97, 'лет'],
        [98, 'лет'],
        [99, 'лет'],
        [100, 'лет']
    ]),
    timeout: 300,
    organization_types: [
        { id:'employer', name:'Работодатель' },
        { id:'freelance', name:'Оператор' }
    ],
    search_task_status: [
        { id:'open', name:'Открыта' },
        { id:'close', name:'Закрыта' }
    ]
}
