<template>
    <div v-if="$context.topActions">
        <v-btn
                v-for="(action, index) in $context.topActions"
                :key="index"
                :color="action.color"
                @click="action.handler()"
        >
            <span>{{ action.title }}</span>
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: 'app-toolbar-action-list'
    }
</script>