<template>
    <v-navigation-drawer
            v-if="items"
            app
            :fixed="fixed"
            :clipped="clipped"
            :floating="floating"
            :temporary="temporary"
            v-model="show"
            class="app-drawer"
            :class="drawerClass"
    >
        <v-list dense>
            <template v-for="(item, i) in items">
                <v-list-group v-if="item.items && item.items.length" :key="i" no-action value="true">
                    <v-list-tile slot="activator" :to="item.to">
                        <v-list-tile-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-avatar v-if="item.count != null">
                            <v-chip color="primary" class="dense white--text">{{ item.count }}</v-chip>
                        </v-list-tile-avatar>
                    </v-list-tile>
                    <template v-for="(subItem, j) in item.items">
                        <v-divider v-if="subItem.divider" :key="`${i}_${j}`"/>
                        <v-list-tile v-else :key="`${i}_${j}`" :to="subItem.to">
                            <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-chip v-if="subItem.color" :color="subItem.color" class="white--text dense">
                                    {{ subItem.title }}
                                  </v-chip>
                                  <span v-else>
                                    {{ subItem.title }}
                                  </span>
                                </v-list-tile-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-avatar v-if="subItem.count != null">
                                <v-chip color="primary" class="dense white--text">{{ subItem.count }}</v-chip>
                            </v-list-tile-avatar>
                        </v-list-tile>
                    </template>
                </v-list-group>
                <v-divider v-else-if="item.divider" :key="i"/>
                <v-list-tile v-else-if="item.header" :key="i">
                    <v-list-tile-content>
                        <v-list-tile-title><h3>{{ item.header }}</h3></v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-else :key="i" :to="item.to" @click="item.click && item.click()" class="c-pointer">
                    <v-list-tile-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-avatar v-if="item.count != null">
                        <v-chip color="primary" class="dense white--text">{{ item.count }}</v-chip>
                    </v-list-tile-avatar>
                </v-list-tile>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name : 'app-drawer',

        props : {
            items : { },
            value : { },
            hover : { },
            dense : {
                type : Boolean
            }
        },

        data() {
            return {
                show      : false,
                fixed     : true,
                clipped   : true,
                floating  : true,
                temporary : false,
            }
        },

        watch : {
            value() {
                this.show = this.value;
            },
            show() {
                this.$emit('input', this.show);
            },
            hover : {
                handler() {
                    if (this.hover) {
                        this.fixed     = false;
                        this.clipped   = false;
                        this.floating  = false;
                        this.temporary = true;
                    } else {
                        this.fixed     = true;
                        this.clipped   = true;
                        this.floating  = true;
                        this.temporary = false;
                        this.show      = true;
                    }
                },
                immediate : true
            }
        },

        computed : {
            drawerClass() {
                let result = '';
                if (this.dense) {
                    result += ' app-drawer--dense ';
                }
                if (this.hover) {
                    result += ' app-drawer--hover ';
                }
                return result;
            }
        }

    }
</script>

<style lang="less">
    .app-drawer {
        .v-avatar {
            justify-content: flex-end;
        }

        &.app-drawer--dense {
            .v-list__tile__action {
                min-width: 40px;
            }
        }
        &.app-drawer--hover {
            width: 400px!important;
            &.v-navigation-drawer--close {
                transform: translateX(-400px)!important;
            }
        }
    }
</style>